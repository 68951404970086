import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";

import { css } from '@emotion/react'
import { fontSize } from '../../styles/typography'
import { container } from '../../styles/grid'
import { colors } from '../../styles/colors'
import Layout from '../../components/layouts'
import RfDFooter from '../RfDFooter/RfDFooter'
import RfDHeader from '../RfDHeader/RfDHeader'
import SignupForm from '../SignupForm/SignupForm'

// Styling for code syntax highlighting.
require("prismjs/themes/prism-coy.css");

let styles;

class Template extends React.Component {
  constructor() {
    super()
    this.state = {
      signupFormHidden: false,
    }

    this.toggleHidden = this.toggleHidden.bind(this)
  }

  toggleHidden() {
    this.setState({
      signupFormHidden: !this.state.signupFormHidden,
    });

    // Google Tag Manager event.
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      if (this.state.signupFormHidden === true) {
        window.dataLayer.push({'event': 'mailinglistSignupFormShow'});
      }
      else {
        window.dataLayer.push({'event': 'mailinglistSignupFormHide'});
      }
    }
  }

  render() {
    const { data } = this.props;
    const { markdownRemark } = data; // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark;
    console.log();
    return (
      <Layout>
        <Helmet
          title={frontmatter.title + " | React for Drupal"}
        />
        <RfDHeader />
        <div css={styles.grid}>
          {!this.state.signupFormHidden && (
            <SignupForm onClose={this.toggleHidden} />
          )}
          <div css={styles.content}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <p><Link to="/">Read more articles ...</Link></p>
        </div>

        <RfDFooter ctaaction={this.toggleHidden.bind(this)} />
      </Layout>
    );
  }

}

export default Template;

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

styles = {
  grid: css`
    ${container(12, false)};

    > * {
      grid-column: main;
    }
  `,
  content: css`
    h1 {
      border-bottom: 1px solid ${colors.lavenderMagenta};
      font-size: ${fontSize(5)};
      margin: 0.5em 0;
    }

    h2 {
      font-size: ${fontSize(2)};
      margin: 2em 0 0.5em 0;
    }
    
    h2:before {
      color: ${colors.dustyGray};
      content: "#";
      margin-left: -1em;
      padding-right: 0.3em;
    }

    ul {
      margin-left: 1em;
    }

    .gatsby-resp-image-wrapper {
      margin-bottom: 4em;
      margin-top: 3em;
      margin-left: -2em;
    }
  `
};
